import React, { useEffect, useState, useMemo } from 'react';
import {
	AzureMap,
	AzureMapDataSourceProvider,
	AzureMapFeature,
	AzureMapLayerProvider,
	AzureMapsProvider,
	IAzureMapOptions,
	AzureMapPopup,
	IAzureMapFeature,
} from 'react-azure-maps';
import {
	AuthenticationType,
	data,
	MapMouseEvent,
	PopupOptions,
} from 'azure-maps-control';
import { set } from 'lodash';

const LocationMarker = ({ lat, long, clinicName }) => {
	console.log(lat, long, clinicName);
	return (
		<AzureMapFeature
			key={clinicName}
			type='Point'
			coordinate={[long, lat]}
			properties={{
				title: clinicName,
			}}
		/>
	);
};

const StoreMap = ({ lat, long, name }) => {
	const [mapData, setMapData] = useState([{ lat, long, name }]);
	const [popupOptions, setPopupOptions] = useState({});
	const [popupProperties, setPopupProperties] = useState({});

	useEffect(() => {
		setMapData([{ lat, long, name }]);
	}, [lat, long, name]);

	const [isVisible, setIsVisible] = useState(false);

	const mapOptions = useMemo(() => {
		return {
			authOptions: {
				authType: 'subscriptionKey',
				subscriptionKey: 'ZANAQtcZvgznl2XpnxCVxcT4lsGe4iNrT4IGb0Owx70',
			},
			center: [long, lat],
			zoom: 15,
			view: 'Auto',
		};
	}, []);

	const memoizedMarkerRender = useMemo(
		() => mapData.map((el) => <LocationMarker {...el} />),
		[mapData]
	);
	const memoizedMapPopup = useMemo(
		() => (
			<AzureMapPopup
				isVisible={isVisible}
				options={popupOptions}
				events={[
					{
						eventName: 'close',
						callback: async () => {
							setIsVisible(false);
						},
					},
				]}
				popupContent={
					<div style={{ padding: '8px 16px' }}>
						<h3>{popupProperties.name}</h3>
					</div> // Inject your JSX
				}
			/>
		),
		[isVisible, popupOptions]
	);
	return (
		<AzureMapsProvider>
			<div
				className='w-full aspect-square overflow-hidden'
				style={{ height: '400px', overflow: 'hidden' }}
			>
				<AzureMap options={mapOptions}>
					{memoizedMapPopup}
					<AzureMapDataSourceProvider
						id={'MultiplePoint AzureMapDataSourceProvider'}
					>
						<AzureMapLayerProvider
							id='MultiplePoint AzureMapDataSourceProvider'
							options={{
								cluster: true,
								clusterRadius: 50,
								clusterMaxZoom: 15,
								iconOptions: {
									image: 'pin-round-blue',
								},
							}}
							events={{
								mousemove: (e) => {
									if (e.shapes && e.shapes.length > 0) {
										const prop = e.shapes[0];

										// Set popup options
										setPopupOptions({
											...popupOptions,
											position: new data.Position(
												prop.data.geometry.coordinates[0],
												prop.data.geometry.coordinates[1]
											),
											pixelOffset: [0, -18],
										});

										if (prop.data.properties)
											// Set popup properties from Feature Properties that are declared on create Feature
											setPopupProperties({
												...prop.data.properties.popUpProp,
											});
									}
								},
							}}
							type='SymbolLayer'
						/>
						{memoizedMarkerRender}
					</AzureMapDataSourceProvider>
				</AzureMap>
			</div>
		</AzureMapsProvider>
	);
};

export default StoreMap;
